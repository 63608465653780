/* General Styles */
body {
    margin: 0;
    font-family: Arial, sans-serif;
  }
  
  h2 {
    text-align: center;
  }
  
  /* Main Container Styles */
  .player-pairing-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px auto;
    width: 100%;
    max-width: 600px;
    padding: 10px;
  }

  /* Estilos para el botón de reset */
.reset-button {
    background-color: #b10f27;
    color: white;
    border: none;
    border-radius: 8px;
    padding: 10px 20px;
    cursor: pointer;
    margin-bottom: 20px;
  }
  
  .reset-button:hover {
    background-color: #ff4d63;
  }


  
  /* Player List Styles */
  ul {
    list-style-type: none;
    padding: 0;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 12px;
    overflow: hidden;
  }
  
  li {
    cursor: pointer;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    background-color: #fff;
    transition: background-color 0.3s ease;
  }
  
  li:last-child {
    border-bottom: none;
  }
  
/*   li.selected {
    background-color: #cce5ff;
  }
  
  li:hover {
    background-color: #f1f1f1;
  }
   */
  /* Team Card Styles */
  .teams-container {
    width: 100%;
  }
  
  .team-card {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 12px;
    margin: 10px 0;
    padding: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .team-player {
    padding: 5px;
    border-radius: 6px;
    background-color: #e6e6e6;
    margin: 5px 0;
  }

    /* Estilos para el botón de reset */
.button-masc {
  background-color: #0ab380;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 10px 20px;
  cursor: pointer;
  margin-bottom: 20px;
  margin: 10px;
}

  /* Estilos para el botón de reset */
  .button-fem {
    background-color: #3f9407;
    color: white;
    border: none;
    border-radius: 8px;
    padding: 10px 20px;
    cursor: pointer;
    margin-bottom: 20px;
    margin: 10px;
  }
  
  /* Responsive Styles */
  @media (max-width: 600px) {
    .player-pairing-container {
      max-width: 80%;
      padding: 10px;
    }
  }
  
  /* Larger Screen Centering */
  @media (min-width: 768px) {
    .player-pairing-container {
      max-width: 50%;
      margin: 0 auto;
    }
  }
  